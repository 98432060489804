<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="gubunItems"
            type="none"
            itemText="codeName"
            itemValue="code"
            name="gubun"
            label="조회구분"
            v-model="searchParam.gubun"
            @datachange="datachange"
          ></c-select>
        </div>
        <div v-if="searchParam.gubun==='chemNm'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            required
            name="chemNm"
            label="화학물질영문명 (2자 이상 입력)"
            v-model="searchParam.chemNm"
          ></c-text>
        </div>
        <div v-if="searchParam.gubun==='casNo'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            required
            name="casNo"
            label="CAS No. (2자 이상 입력)"
            v-model="searchParam.casNo"
          ></c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="regulFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="regulFlag"
            label="규제물질 대상여부"
            v-model="searchParam.regulFlag"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"></div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-select
            type="search"
            codeGroupCd="REGULATION_BILL_API_CD"
            itemText="codeName"
            itemValue="code"
            label="관련 법규"
            name="regulationBillCds"
            v-model="searchParam.regulationBillCds"
            @datachange="billCdChange">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-select
            type="search"
            :comboItems="regulatedItems"
            itemText="regulatedItemName"
            itemValue="regulatedItemCd"
            label="규제 법규"
            name="regulatedItemCds"
            v-model="searchParam.regulatedItemCds">
          </c-multi-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="화학물질 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chemBase',
  data() {
    return {
      searchParam: {
        gubun: 'casNo',
        casNo: '',
        chemNm: '',
        regulFlag: 'Y',
        regulationBillCds: [],
        regulatedItemCds: [],
      },
      grid: {
        columns: [
          {
            name: 'chem',
            field: 'chem',
            label: '화학물질명',
            child: [
              {
                name: 'chemNmEn',
                field: 'chemNmEn',
                label: '영문',
                align: 'center',
                sortable: true,
                style: 'width: 120px',
                type: 'link'
              },
              {
                name: 'chemNmKr',
                field: 'chemNmKr',
                label: '국문',
                align: 'center',
                sortable: true,
                style: 'width: 120px',
              },
            ]
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            sortable: true,
            style: 'width: 80px',
          },
          {
            name: 'molecularFormula',
            field: 'molecularFormula',
            label: '화학식',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'regulFlag',
            field: 'regulFlag',
            label: '규제물질<br/>대상여부',
            style: 'width:90px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      gubunItems: [
        { code: 'casNo', codeName: 'CAS No. 조회' },
        { code: 'chemNm', codeName: '화학물질영문명 조회' },
      ],
      regulFlagItems: [
        { code: 'Y', codeName: '규제O' },
        { code: 'N', codeName: '규제X' },
      ],
      searchLawItems: [],
      searchLaw: [],
      regulatedItems: [],
      originalData: [],
      filterData: [],
      editable: true,
      listUrl: '',
      regsUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.base.chemBase.list.url
      this.regsUrl = selectConfig.mdm.mam.chem.regs.url;
      // code setting
      // list setting
      // this.getList();
    },
    getList() {
      if (this.searchParam.gubun === 'casNo') {
        if (!this.searchParam.casNo
          || (this.searchParam.casNo && this.searchParam.casNo.length < 2)
        ) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: 'CAS No.를 2자 이상 입력한 후 이용바랍니다.',
            type: 'warning', // success / info / warning / error
          });
          return;
        }
      } else {
        if (!this.searchParam.chemNm
          || (this.searchParam.chemNm && this.searchParam.chemNm.length < 2)
        ) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '화학물질영문명을 2자 이상 입력한 후 이용바랍니다.',
            type: 'warning', // success / info / warning / error
          });
          return;
        }
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    datachange() {
      if (this.searchParam.gubun === 'casNo') {
        this.$set(this.searchParam, 'chemNm', '')
      } else {
        this.$set(this.searchParam, 'casNo', '')
      }
    },
    billCdChange() {
      this.$http.url = this.regsUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        searchItems: this.searchParam.regulationBillCds
      };
      this.$http.request((_result) => {
        this.regulatedItems = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '화학물질 상세';
      this.popupOptions.param = {
        mdmChemId: row ? row.mdmChemId : '',
      };
      this.popupOptions.target = () => import(`${'./chemBaseDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
